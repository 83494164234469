import { ConditionalAccessPolicy, DirectoryRole, User } from '@microsoft/microsoft-graph-types-beta';

import { PerUserMfaState } from 'src/app/stores/client/graph/authentication/perUserMfaState/model';
import { Mailbox } from 'src/app/stores/client/powershell/exo/mailbox/model';

import { Baseline } from './../../../../stores/client/sway/baseline/model';


export enum MFAShortStory {
    enforcedSecurityDefaults = 'Enforced by security defaults',
    enforcedCap = 'Enforced by conditional access policy',
    enabledPerUser = 'Enabled (per user)',
    enforcedPerUser = 'Enforced (per user)',
    disabled = 'Disabled',
}

export interface ExtendedUser extends User {
    roles: DirectoryRole[];
    risksExclusions: UserRiskExclusion[];
    authMethod: string;
    perUserMfaState: PerUserMfaState;
    mfaShortStory: MFAShortStory;
    isGlobalAdmin: boolean;
    mailbox: Mailbox;
    rolesLabel: string[];
}
export interface RiskCounts {
    type: RisksEnum;
    critical: number;
    danger: number;
    warning: number;
    info: number;
    total: number;
}

export const GLOBAL_ADMIN_TEMPLATE_ID = '62e90394-69f5-4237-9190-012177145e10';

export const SharedMailboxesCommonNames = [
    'admin',
    'accounts',
    'social',
    'support',
    'marketing',
    'info',
    'DPO',
    'billing',
    'help',
    'supportdesk',
    'sales',
    'development',
    'team',
    'contact',
    'office',
    'welcome',
    'business',
    'connect',
    'enquiries',
    'euroinfo',
    'solutions',
    'customerservices',
    'customercare',
    'mail',
    'mailbox',
    'office',
    'partners',
    'consult',
    'export',
    'recruiting'
];

export enum RiskLevelEnum {
    CRITICAL = 'critical',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    BASELINE_DEVIATED = 'BASELINE DEVIATED'
}


export enum RiskLevelOrderEnum {
    CRITICAL = 5,
    DANGER = 4,
    WARNING = 3,
    BASELINE_DEVIATED = 2,
    BASELINE_PASSED = 1,
    INFO = 0,
}

export enum RisksEnum {
    NO_MFA = 'MFA',
    ADMIN_With_MAILBOX = 'Admin Mailboxes',
    ANONYMOUS_ADMINS = 'Anonymous Admins',
    SHARED_MAILBOX = 'Shared Mailboxes', // SHARED MAILBOX THAT IS  A  FULL USER ACCOUNT
    DORMANT_ACCOUNT = 'Dormant Accounts',
    DORMANT_ADMIN = 'Dormant Admins',
    DORMANT_GUEST = 'Guests'
}

export const IconsSrc = {
    [RisksEnum.NO_MFA]: {
        icon: 'mfa.svg',
        critical: 'admin-mfa-critical.svg',
        danger: 'user-mfa-danger.svg',
        warning: 'user-mfa-warning.svg'
    },
    [RisksEnum.SHARED_MAILBOX]: {
        icon: 'shared-mailbox.svg',
        critical: '',
        danger: 'shared-mailbox-danger.svg',
        warning: ''
    },
    [RisksEnum.DORMANT_ACCOUNT]: {
        icon: 'dormant-accounts.svg',
        critical: 'dormant-accounts-critical.svg',
        danger: 'dormant-accounts-danger.svg',
        warning: 'dormant-accounts-warning.svg'
    },
    [RisksEnum.DORMANT_ADMIN]: {
        icon: 'dormant-accounts.svg',
        critical: 'dormant-accounts-critical.svg',
        danger: 'dormant-accounts-danger.svg',
        warning: 'dormant-accounts-warning.svg'
    },
    [RisksEnum.ADMIN_With_MAILBOX]: {
        icon: 'admins-with-mailbox.svg',
        critical: 'admin-with-mailbox-critical.svg',
        danger: 'admin-with-mailbox-danger.svg',
        warning: ''
    },
    [RisksEnum.ANONYMOUS_ADMINS]: {
        icon: 'shared-admins.svg',
        critical: 'shared-admin-critical.svg',
        danger: 'shared-admin-danger.svg',
        warning: ''
    },
    [RisksEnum.DORMANT_GUEST]: {
        icon: 'dormant-guest.svg',
        critical: '',
        danger: 'dormant-accounts-danger.svg',
        warning: ''
    }
};

export const RisksDescriptions = {
    [RisksEnum.ADMIN_With_MAILBOX]: {
        risk: `
                <p>
                    An administrator account is a powerful identity. Using these accounts for anything but the configuration of users and settings, and by a certain user, leaves your business unnecessarily vulnerable.
                </p>

                <ul>
                    <li>
                    Inadvertent exposure of administration credentials to malicious actors, due to:

                    <ul>
                        <li>
                        Unnecessary Sharing of Credentials.
                        </li>
                        <li>
                        Unnecessary Overuse of sensitive credentials.
                        </li>
                    </ul>
                    </li>

                    <li>
                    Inadvertent or accidental damage caused by user in a non admin capacity .
                    </li>
                    <li>
                    Lack of auditing / trackability for all admin activities.
                    </li>
                </ul>
            `,

        solution: `
                <p>
                If the mailbox is required, then remove administrative privileges from these accounts and if necessary, create a new separate administrative account (unlicenced) for that user.
                </p>

                <p>
                If the mailbox is not required then simply remove it, and save the cost of a mailbox licence in the process.
                </p>
            `
    },
    [RisksEnum.NO_MFA]: {
        risk:
            `
                <p>
                     Malicious actors often gain access to your organization through simply learning, stealing or deciphering user credentials. This is the most common type of security breach.
                </p>
            `,

        solution:
            `
                <p>
                Enforcing Multi Factor Authentication (MFA) is a fantastic way of preventing this from happening because it enforces users to use a second authentication mechanism such as a personalised authenticator app on their personal mobile devices.  We would advise that ALL Admin accounts and ALL mailboxes be secured with MFA.
                </p>
            `
    },
    [RisksEnum.ANONYMOUS_ADMINS]: {
        risk:
            `
                <p>
                Using a common anonymous administration account(s) creates several issues
                </p>


                <ul>
                <li>
                Inadvertent exposure of administration credentials to malicious actors, due to:

                <ul>
                    <li>
                    Unnecessary Sharing of Credentials.
                    </li>
                    <li>
                    Unnecessary Overuse of sensitive credentials.
                    </li>
                </ul>
                </li>

                <li>
                Inadvertent or accidental damage caused by user in a non admin capacity.
                </li>
                <li>
                The organiation has little or no traceability on who is using the account.
                </li>
            </ul>
            `,

        solution:
            `
                <p>
                Each user who requires administration privileges should be given a separate administration account (without a mailbox) to use only if, and when, they need to perform administration activities.
                </p>
            `
    },
    [RisksEnum.SHARED_MAILBOX]: {
        risk:
            `
                <p>
                Sharing mailboxes is a common business requirement. However directly sharing the login credentials of a full user mailbox, anonymously, leaves the business vulnerable.
                </p>

                <ul>
                    <li>
                    Unnecessary sharing and management of credentials.
                    </li>

                    <li>
                    Lack of visibility and audit of which user is communicating of performing mailbox administration.
                    </li>
                </ul>
            `,

        solution:
            `
                <p>
                Use a Shared Mailbox instead and Identify users who need to “send as” or have “full access” to the shared account. Then remove the (anonymous) login.
                </p>
            `

    },
    [RisksEnum.DORMANT_ACCOUNT]: {
        risk:
            `
                <p>
                Accounts that are no longer in use, can be silent back doors into the organizations, giving mal-actors unattended access to sensitive business data mail, OneDrive, Teams, SharePoint, or a means to improperly act under the guise of the organisation.
                </p>
            `,

        solution:
            `
                <p>
                Identify ex-employees who still have their accounts in use, and remove admin/other accounts which are no longer required.
                </p>
            `

    },
    [RisksEnum.DORMANT_ADMIN]: {
        risk:
            `
                <p>
                Admins that are no longer in use, can be silent back doors into the organizations, giving mal-actors unattended access to sensitive business data mail, OneDrive, Teams, SharePoint, or a means to improperly act under the guise of the organisation.
                </p>
            `,

        solution:
            `
                <p>
                Identify admins who still have their accounts in use, and remove admin/other admins which are no longer required.
                </p>
            `

    },
    [RisksEnum.DORMANT_GUEST]: {
        risk:
            `
                    <p>
                    Guest users are a great way to identify and manage legitimate access to business assets by third party users. However, these requirements can become redundant and relationships can change, leaving the organisation exposed.
                    </p>
                `,

        solution:
            `
                    <p>
                    Review all guests and ascertain if they are still trusted parties and their access is still necessary.
                    </p>
                `
    }
};

export interface UserRiskExclusion {
    type: RisksEnum;
    order: RiskLevelOrderEnum;
    risk?: Risk;
    exclusion?: Exclusion;
}

export interface Risk {
    level: RiskLevelEnum;
    displayName: string;
}


export interface Exclusion {
    reason: string;
    route?: string;
    passed?: boolean;
    baseline?: Baseline;
    linkedToBaseline?: boolean;
    mfaForcedByPolicy?: boolean;
    policy?: ConditionalAccessPolicy;
}
